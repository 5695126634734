import './css/Title.css'

export default function Title() {


    return(
      <div className="title">
        <h2>Felton Family Secret Santa!</h2>
      </div>
    )
}
