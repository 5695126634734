import { people } from './people'

export const whoHasWho = {
  tracy: people.alyssa,
  raul: people.sam,
  alyssa: people.chris,
  sam: people.courtney,
  patti: people.jamie,
  roger: people.tracy,
  jamie: people.raul,
  courtney: people.roger,
  chris: people.patti,
}

