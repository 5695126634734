const alyssas_list = [
    "Candle Wick Cutter",
    "Microwaveable Slippers",
    "Vegetable Chopper",
]

const sams_list = [
    "Handheld milk frother",
    "A slim-ish wallet",
    "Lightweight gloves",
    "Whiskey is always good",
]

const tracys_list = [
    "Gift Cards - Nordstrom, Starbucks, Target",
    "Solid color scarf (grey, cream, black, something neutral)",
]

const rauls_list = [
    "Raul doesn't have a list yet",
    "Make sure to send your list to Sam",
]

const pattis_list = [
    "App Watch band-S?",
    "Potholders",
    "Non-stock pan safe tongs",
    "Outlive by Peter Attia MD",
    "Gift cards - Brick & Mortar Books, Lucky You or Lucky Home, Nordstrom",
    "Glassybaby rechargeable tealight",
]

const rogers_list = [
    "The Exchange by John Grisham",
    "New Biking Gloves",
    "Front Bike Light",
    "Biking Shirt",
    "Winter Fleece, lightweight",
    "Lightweight gloves, for walking",
    "Craftsman 20.5\" Tool Box",
    "Craftsman 140 Pc Drill & Drive Set",
]

const jamies_list = [
    "Jamie doesn't have a list yet",
    "Make sure to send your list to Sam",
]

const courtneys_list = [
    "Origins Peace of Mind on the spot relief",
    "Travel size/roll on Philosophy Amazing Grace Ballet Rose perfume",
    "Gifts cards - Nordstrom, Vuori, or the Loft",
    "Dine In, Cookbook by Alison Roman",
    "Cribsheet, book by Emily Oster",
    "Ugg Slippers, size 7",
    "Wooden or silicon cooking utensils",
    "Something fun to do with Grayson",
]

const chris_list = [
    "\"athleisure\" clothes - sweatpants(L), shorts(L), shirt(XL)",
    "Fun toy, game, or activity to do with Grayson",
    "Allbirds shoes, size 12",
    "Gift cards - REI, Lululemon, Vuori, Nordstrom",
]

export const lists = {
    sam: sams_list,
    alyssa: alyssas_list,
    tracy: tracys_list,
    raul: rauls_list,
    patti: pattis_list,
    roger: rogers_list,
    jamie: jamies_list,
    courtney: courtneys_list,
    chris: chris_list
}
