import './css/Lights.css'

export default function Lights() {


    return(
      <div className="lights">
          <ul className="wire">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          {/*<h2>Secret Santa!</h2>*/}
      </div>
    )
}
