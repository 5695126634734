import { lists } from './lists'

export const people = {
    sam: { name: "Sam", list: lists.sam },
    alyssa: { name: "Alyssa", list: lists.alyssa },
    tracy: { name: "Tracy", list: lists.tracy },
    raul: { name: "Raul", list: lists.raul},
    patti: { name: "Patti", list: lists.patti },
    roger: { name: "Roger", list: lists.roger },
    jamie: { name: "Jamie", list: lists.jamie},
    courtney: { name: "Courtney", list: lists.courtney},
    chris: { name: "Chris", list: lists.chris}
}

